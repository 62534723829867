* {
  margin: 0;
}
:root {
  --colorOne: lightgray;
  /* --colorTwo: rgb(233, 235, 240); */
  --colorTwo: white;
  --colorThree: grey;
  --headerColor: rgb(211, 4, 4);
}

.night-mode { 
  --colorOne: black;
  /* --colorTwo: rgb(233, 235, 240); */
  --colorTwo: darkgray;
  --colorThree: black;
}

html {
  width: 100vw;
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colorOne);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  border: none;
  color: inherit;
}
