.photo {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: url(https://i.imgur.com/VKqh77n.jpg) fixed top no-repeat;
    background-size: cover;
}
.photo__container{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.photo__image{
    border-radius: 50%;
    border: 3px solid var(--colorThree);
    box-shadow: 0 3px 3px black;
    width: 100px;
}

.photo__name{
    font-size: 40px;
    font-weight: bold;
    color: white;
    text-shadow: 0 3px 3px black;
}

.photo__about{
    width: 35%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    border-radius: 20px;
}

.about__title{
    font-size: 40px;
    padding: 20px;
    color: white;
    text-shadow: 0 3px 3px black;
    text-align: center;
    font-family: 'Abril Fatface', cursive;
}

.about__title__span{
    color: var(--headerColor);
}

.about__text{
    font-size: 20px;
    color: white;
    padding: 30px;
    font-weight: bold;
    text-shadow: 0 3px 3px black;
}

@media only screen and (min-width: 768px){
    .photo__image {
        width: 300px;
    }

    .about__title {
        font-size: 60px;
        padding: 30px;
    }

    .about__text {
        font-size: 30px;
    }

    .photo__container {
        padding-top: 100px;
        padding-bottom: 100px;
    }

}