.skills {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0 3px 3px black;
    background-image: linear-gradient(white, lightgray);
}
.skills__subContainer {
    display: flex;
    flex-wrap: wrap;
    color: black;
    text-shadow: 0 3px 3px gray;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
}

.skills__icon {
    color: white;
}

.skills__text {
    padding: 100px;
}

.skills__text > p {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 30px;
    color: white;
    text-shadow: 0 3px 3px black;
}

.skills__image {
    object-fit: contain;
    border-radius: 10px;
    height: 100%;
    margin-left: 30px;
    margin-right: 30px;
}

h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    font-family: 'Abril Fatface', cursive;
}

.skills__logo__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.skills__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skills__logo{
    font-size: 50px;
}

.skills__span {
    color: var(--headerColor);
}

@media only screen and (min-width: 768px){
    .skills__logo__container {
        margin: 50px;
    }
}