.contact {
    height: 100vh;
    background-image: linear-gradient(lightgray, white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact__span {
    color: var(--headerColor);
}

.contact__container > a {
    color: inherit;
    text-shadow: 0 3px 3px black;
    margin: 50px;
}

.contact__container > a:hover{
    color: gray;
}

.contact__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
