.modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__modal {
    margin: 0 auto;
}

.modal > button:focus {
    outline: none;
}

.modal__image {
    width: 375px;
    box-shadow: 0px 4px 4px black;
}
.modal__image:hover{
    box-shadow: 10px 15px 15px black;
}

.modal__textImage {
    width: 100%;
}

.modal__btn {
    width: 375px;
    padding: 0 !important;
    border: none !important;
    background: none !important;
    cursor: pointer;
    margin: 20px;
}

.modal__linkIconContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    align-items: center;
}

.modal__linkIconContainer > a {
    color: gray;
}

.modal__linkIcon:hover {
    color: white;
}

.modal__icon__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}