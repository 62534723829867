.portfolio {
    padding-top:50px;
    background-image: linear-gradient(white, lightgray);
}

.portfolio__project {
    display: flex;
    flex-direction: column;
}

.portfolio__header {
    color: black;
    padding-bottom: 80px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    text-shadow: 0 3px 3px gray;
    font-family: 'Abril Fatface', cursive;
}

.portfolio__header__span{
    color: var(--headerColor);
}

.portfolio__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0 3px 3px black;
    padding-bottom: 50px;
}

.portfolio__projectWrapper {
    width: 375px;
    margin: 20px;
}

@media only screen and (min-width: 768px){
    .portfolio__header {
        font-size: 60px;
    }
}